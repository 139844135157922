import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Thanks = () => (
  <Layout>
    <SEO title="Thanks" />

    <div className="container contact thanks-container">
      <div className="contact__content">
        <h1>Thanks for reaching out!</h1>
        <p>We got your message and will follow up shortly.</p>
      </div>
    </div>
  </Layout>
)

export default Thanks
